import React, { Component } from "react";
import "./floating-player.css";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

class FloatingPlayer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <AudioPlayer
        src={this.props.mp3Path}
        showJumpControls={false}
        showSkipControls={false}
        // other props here
      />
    );
  }
}

export default FloatingPlayer;
