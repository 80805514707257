import React, { Component } from "react";
import "./inline-player.css";

class InlinePlayer extends Component {
  constructor(props) {
    super(props);

    var _player = (
      <React.Fragment>
        <audio
          className={"inline-player"}
          id={"audio-" + this.props.id}
          src={this.props.mp3Path}
          controls
        ></audio>
      </React.Fragment>
    );

    this.state = { player: _player };
  }

  render() {
    return <div>{this.state.player}</div>;
  }
}

export default InlinePlayer;
