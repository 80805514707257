import React, { Component } from "react";
import GlowingText from "../glowingText/glowingText";
import "./generic-content.css";

class GenericContent extends Component {
  render() {
    return (
      <React.Fragment>
        {/* Masthead*/}
        <section className="masthead bg-primary text-white">
          <div className="container d-flex flex-column content-area">
            test content!
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default GenericContent;
