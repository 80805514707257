import React, { Component } from "react";
import GlowingText from "../glowingText/glowingText";

class Header extends Component {
  render() {
    return (
      <React.Fragment>
        {/* Masthead*/}
        <header className="masthead bg-primary text-white text-center">
          <div className="container d-flex align-items-center flex-column">
            {/* Masthead Avatar Image*/}
            <span className="flicker">
              <img
                className="masthead-avatar mb-5"
                src="assets/img/cevelyn-icon.png"
                alt=""
              />
            </span>
            {/* Masthead Heading*/}
            <h1 className="masthead-heading text mb-0 glowing-text">
              ai based
              <br />
              emotion aware <br />
              music composer <br />
            </h1>
            {/* Icon Divider*/}
            <div className="divider-custom divider-light">
              <div className="divider-custom-line"></div>
              <div className="divider-custom-icon">
                <i className="fas fa-headphones-alt"></i>
              </div>
              <div className="divider-custom-line"></div>
            </div>
            {/* Masthead Subheading*/}
            <p className="masthead-subheading font-weight-light mb-0">
              Let Cevelyn compose{" "}
              <span className="bold-text">royalty-free</span>, awesome songs for
              you!
            </p>
            <p>
              <small>
                <a href="http://app.cevelyn.com/signup">Sign up</a> now to
                become a beta-user
              </small>
            </p>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default Header;
