import React, { Component } from "react";
import GlowingText from "../glowingText/glowingText";
import "./navigation.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

class Navigation extends Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <nav
            className="navbar navbar-expand-lg bg-secondary text fixed-top"
            id="mainNav"
          >
            <div className="container">
              <a className="navbar-brand js-scroll-trigger" href="/">
                <GlowingText></GlowingText>
              </a>
              <button
                className="navbar-toggler navbar-toggler-right text-uppercase font-weight-bold bg-primary text-white rounded"
                type="button"
                data-toggle="collapse"
                data-target="#navbarResponsive"
                aria-controls="navbarResponsive"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="fas fa-bars"></i>
              </button>
              <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item mx-0 mx-lg-1 hidden">
                    <a
                      className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                      href="#portfolio"
                    >
                      How it works?
                    </a>
                  </li>
                  <li className="nav-item mx-0 mx-lg-1 hidden">
                    <a
                      className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                      href="#about"
                    >
                      Inspiration
                    </a>
                  </li>
                  <li className="nav-item mx-0 mx-lg-1 hidden">
                    <a
                      className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                      href="#about"
                    >
                      Pricing
                    </a>
                  </li>
                  <li className="nav-item mx-0 mx-lg-1 hidden">
                    <a
                      className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                      href="#about"
                    >
                      Song Licensing
                    </a>
                  </li>
                  <li className="nav-item mx-0 mx-lg-1 hidden">
                    <a
                      className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                      href="#about"
                    >
                      HotOrNot
                    </a>
                  </li>
                  <li className="nav-item mx-0 mx-lg-1">
                    <a
                      className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger nav-login"
                      href="http://app.cevelyn.com/signup"
                    >
                      Login / Sign up
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </Router>
      </React.Fragment>
    );
  }
}

export default Navigation;
