import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        {/* Masthead*/}
        {/* Footer*/}
        <footer className="footer text-center">
          <div className="container">
            <div className="row">
              {/* Footer Location*/}
              <div
                className="col-lg-3 mb-5 mb-lg-0 show-on-left"
                style={{ display: "none" }}
              >
                <h4 className="text mb-4 medium-text">Company</h4>
                <p className="lead mb-0 tiny-text">
                  <a href="">About</a>
                  <br />
                  <a href="">Career</a>
                  <br />
                  <a href="">Investors</a>
                  <br />
                  <a href="">Vendors</a>
                  <br />
                  <a href="">Events</a>
                  <br />
                  <a href="">Press Kit</a>
                  <br />
                  <a href="">Contact us</a>
                  <br />
                </p>
              </div>
              {/* Footer About Text*/}
              <div
                className="col-lg-3 show-on-left"
                style={{ display: "none" }}
              >
                <h4 className="text mb-4 medium-text">Community</h4>
                <p className="lead mb-0 tiny-text">
                  <a href="">Artists</a>
                  <br />
                  <a href="">Developers</a>
                  <br />
                  <a href="">Gamers</a>
                  <br />
                  <a href="">Content Creators</a>
                  <br />
                  <a href="">Educators</a>
                  <br />
                  <a href="">Store</a>
                </p>
              </div>
              <div
                className="col-lg-3 show-on-left"
                style={{ display: "none" }}
              >
                <h4 className="text mb-4 medium-text">Product</h4>
                <p className="lead mb-0 tiny-text">
                  <a href="">How Cevelyn works?</a>
                  <br />
                  <a href="">Emotions</a>
                  <br />
                  <a href="">Pricing</a>
                  <br />
                  <a href="">FAQ</a>
                  <br />
                  <a href="">Report a bug</a>
                  <br />
                  <a href="">Testimonials</a>
                  <br />
                  <a href="">Inspiration</a>
                  <br />
                  <a href="">Support</a>
                </p>
              </div>

              {/* Footer Social Icons*/}
              {/* <div className="col-lg-3 mb-5 mb-lg-0 show-on-left"> */}
              <div className="col-lg-12 mb-5 mb-lg-0 show-on-left">
                <div style={{ display: "none" }}>
                  <h4 className="text mb-4 medium-text">Useful Links</h4>
                  <a className="tiny-text" href="">
                    Works from the community
                  </a>
                  <br />
                  <a className="tiny-text" href="">
                    Learning Hub
                  </a>
                  <br />
                  <br />
                </div>
                <div style={{ textAlign: "center" }}>
                  <h4 className="text mb-4 medium-text">Follow us!</h4>
                  {/* <a
                    className="btn btn-outline-light btn-social mx-1"
                    href="#!"
                  >
                    <i className="fab fa-fw fa-facebook-f"></i>
                  </a> */}
                  <a
                    className="btn btn-outline-light btn-social mx-1"
                    href="https://www.instagram.com/cevelyn.ai"
                  >
                    <i className="fab fa-fw fa-instagram"></i>
                  </a>
                  <a
                    className="btn btn-outline-light btn-social mx-1"
                    href="https://twitter.com/cevelynai"
                  >
                    <i className="fab fa-fw fa-twitter"></i>
                  </a>
                  <a
                    className="btn btn-outline-light btn-social mx-1"
                    href="https://www.linkedin.com/company/cevelyn/people/"
                  >
                    <i className="fab fa-fw fa-linkedin-in"></i>
                  </a>
                  {/* <a
                    className="btn btn-outline-light btn-social mx-1"
                    href="#!"
                  >
                    <i className="fab fa-fw fa-dribbble"></i>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
