import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Navigation from "./components/navigation/navigation";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import BottomLinks from "./components/bottomLinks/bottomLinks";
import InlinePlayer from "./components/inlinePlayer/inlinePlayer";
import FloatingPlayer from "./components/floatingPlayer/floatingPlayer";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import GenericContent from "./components/genericContent/genericContent";

function App() {
  return (
    <Router>
      <Navigation></Navigation>
      <Switch>
        <Route path="/test">
          <GenericContent></GenericContent>
        </Route>
        <Route path="/">
          <Header></Header>
        </Route>
      </Switch>
      <Footer></Footer>
      <BottomLinks></BottomLinks>
      {/* Scroll to Top Button (Only visible on small and extra-small screen sizes)*/}
      <div className="scroll-to-top d-lg-none position-fixed">
        <a
          className="js-scroll-trigger d-block text-center text-white rounded"
          href="#page-top"
        >
          <i className="fa fa-chevron-up"></i>
        </a>
      </div>
      <FloatingPlayer mp3Path={"/files/cev-comp-v2.mp3"}></FloatingPlayer>
    </Router>
  );
}

export default App;
