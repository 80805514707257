import React, { Component } from "react";
import "./glowing-text.css";

class GlowingText extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="overlay">
          <div className="sign">
            <span className="flicker">c</span>
            ev
            <span className="fast-flicker">e</span>
            ly
            <span className="slow-flicker">n</span>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default GlowingText;
