import React, { Component } from "react";

class BottomLinks extends Component {
  render() {
    return (
      <React.Fragment>
        {/* Copyright Section*/}
        <div
          className="copyright py-4 text-center text-white"
          style={{ display: "none" }}
        >
          <div className="container">
            <div className="footer-links tiny-text">
              <a href="">Legal</a>
              <a>|</a>
              <a href="">Privacy Policy</a>
              <a>|</a>
              <a href="">Cookies</a>
              <a>|</a>
              <a href="">About Ads</a>
            </div>
            <small>Copyright © Cevelyn {new Date().getFullYear()}</small>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BottomLinks;
